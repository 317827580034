.small-order-positions {
	&__list {
		display: flex;
		flex-wrap: wrap;
		gap: 12px;
	}

	&__item {

	}
}
