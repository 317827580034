.success-order {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px;
	gap: 16px;

	border: 1px solid rgba(162, 162, 162, 0.2);
	border-radius: 12px;

	&__header {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0 0 16px;
		gap: 4px;
		width: 100%;
		min-height: 40px;

		border-bottom: 1px solid rgba(162, 162, 162, 0.2);
		font-size: 16px;
		line-height: 24px;
		color: rgba(0, 0, 0, 0.8);
	}

	&__body {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
	}

	&__footer {
		width: 100%;
	}

	&__positions {
		display: flex;
		flex-direction: column;
		gap: 8px;

		&-title {
			font-weight: 600;
			font-size: 14px;
			line-height: 20px;
			color: rgba(0, 0, 0, 0.4);
		}
	}

	&__delivery {
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;

		color: rgba(0, 0, 0, 0.8);

		&-title {
			font-weight: 700;
			line-height: 20px;
		}
	}
}
