.order-page {
	h1 {
		margin-bottom: 16px;
	}

	.cart-success-order {
		display: flex;
		flex-direction: column;
		gap: 40px;

		&__text {
			font-weight: 400;
			font-size: 16px;
			line-height: 28px;
			color: #000000;

			a {
				color: #1536A1;

				&:active,
				&:hover {
					color: #2247bd;
				}
			}
		}
	}

	.success-order {
		max-width: 700px;
	}
}
