.ant-input {
	box-sizing: border-box;
	padding: 4px 12px;
	gap: 8px;
	height: 48px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	width: 100%;
	&,
	&:focus {
		outline: none;
		box-shadow: none;
		background: #FFFFFF;
		border: 1px solid rgba(162, 162, 162, 0.4);
		border-radius: 8px !important;
	}

	&.error {
		color: rgba(255, 75, 85, 1);
		border-color: rgba(255, 75, 85, 1);
	}
}

.error {
	color: rgba(255, 75, 85, 1);
	border-color: rgba(255, 75, 85, 1);
}

.st-tel-input.react-tel-input  input.ant-input {
	@extend .ant-input;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:focus-within,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

.ant-input-affix-wrapper {
	.ant-input:-webkit-autofill,
	.ant-input:-webkit-autofill:hover,
	.ant-input:-webkit-autofill:focus,
	.ant-input:-webkit-autofill:focus-within,
	.ant-input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 30px #fff inset !important;
	}
}
.ant-input-affix-wrapper {
	&,
	&:focus-within {
		padding: 0 12px;
		outline: none;
		box-shadow: none;
		background: #FFFFFF;
		border: 1px solid rgba(162, 162, 162, 0.4);
		border-radius: 8px;

		.ant-input {
			&,
			&:focus {
				height: 46px;
				outline: none;
				box-shadow: none;
				background: transparent;
				border: none;
				border-radius: 0;
			}
		}
	}
}

.form {
	display: flex;
	flex-direction: column;
	gap: 16px;

	&__row {
		&_3-cols {
			display: grid;
			gap: 16px;
			grid-template-columns: repeat(3, 1fr);
		}
	}

	&__row {
		&_2-cols {
			display: grid;
			gap: 16px;
			grid-template-columns: repeat(2, 1fr);
		}
	}

	&__field {
		display: flex;
		flex-direction: column;
		gap: 8px;

		&-label {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;

			color: rgba(0, 0, 0, 0.6);
		}

		&-input {
			.ant-input-affix-wrapper.error {
				color: rgba(255, 75, 85, 1);
				border-color: rgba(255, 75, 85, 1);
			}
		}
	}
}

.user-form {
	display: flex;
	flex-direction: column;
	gap: 8px;

	&__row {
		&-input {
			&.error {
				input {
					color: rgba(255, 75, 85, 1);
					border-color: rgba(255, 75, 85, 1);
				}
			}
		}

		&-help {
			font-size: 12px;
			line-height: 16px;
			color: #9D9DA5;
		}
	}
}

.ant-radio-group {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.ant-radio-wrapper {
	display: flex;
	gap: 8px;

	.ant-radio {
		&.ant-radio-checked {
			.ant-radio-inner {
				background: rgba(255, 184, 0, 0.8);
			}
		}

		.ant-radio-inner {
			height: 20px;
			width: 20px;
			border: 0;
			background: rgba(0, 0, 0, 0.05);
			display: flex;
			align-items: center;
			justify-content: center;

			&:after {
				display: block;
				margin: 0;
				padding: 0;
				top: auto;
				left: auto;
				position: relative;
				height: 16px;
				width: 16px;
				box-sizing: border-box;
				background: #000000;
			}
		}
	}
}

.mobile-form {
	display: flex;
	flex-direction: column;
	gap: 16px;

	&__row {
		display: flex;
		width: 100%;
		gap: 16px;

		&_two-cols {
			.mobile-form {
				&__field {
					width: calc(50% - 8px);
				}
			}
		}
	}

	&__section-title {
		font-weight: 600;
		font-size: 16px;
		line-height: 22px;
		color: #000000;
	}

	&__field {
		display: flex;
		width: 100%;
		align-items: center;

		&-input {
			width: 100%;
		}
	}
}
