.search-result {
	display: flex;
	flex-direction: column;
	gap: 40px;

	&-section {
		display: flex;
		flex-direction: column;
		gap: 22px;

		&__title {
			font-size: 22px;
			line-height: 28px;
			font-weight: 500;
		}
	}
}
